// context/LocationContext.js
import React, { createContext, useState, useEffect } from "react";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        console.log("Fetching location data...");
        const response = await fetch('/api/location');
        const data = await response.json();
        console.log("Location data fetched:", data);
        if (data.closestShowroomToUser) {
          const locationData = data.closestShowroomToUser;
          setLocation(locationData);
          localStorage.setItem('userLocation', JSON.stringify({
            showroom: locationData,
            userGeo: data.userLocation || { state: "not available", city: "not available" }
          }));
        } else {
          console.error("No closestShowroomToUser in fetched data");
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      try {
        const parsedData = JSON.parse(storedLocation);
        setLocation(parsedData.showroom);
        console.log("Location data loaded from localStorage:", parsedData);
      } catch (error) {
        console.error("Error parsing stored location:", error);
        localStorage.removeItem('userLocation');
        fetchLocation();
      }
    } else {
      fetchLocation();
    }

    ['closestTenant', 'locationFetched', 'locationFetchedv1', 'usersLocationData'].forEach(key => {
      localStorage.removeItem(key);
    });
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

